<template>
  <section id="support">
    <div class="page">
      <illu-support class="illustration" />
      <p>
        We at Doppio are here to help you with any questions or concerns you may have. <br>
        We offer a variety of ways to get in touch with us, including email and live chat.
      </p>

      <p>
        If you're looking for more information on how to use our product, we've got it!
        <a :href="docBaseUrl">Documentation</a> is a great place to start.
        You can find code examples that will make your development process easier than ever before.
      </p>

      <p>
        If you're interested in learning more about what you can do with our product, check out our cookbook!
      </p>

      <p>If you have any question about Doppio, please contact us with our chat, we will be happy to help.</p>
      <p>
        You can email us too at:
        <a href="mailto:support@doppio.sh">support@doppio.sh</a>
      </p>
    </div>
  </section>
</template>

<script>
import illuSupport from '@/assets/img/illu-support.svg?inline';

export default {
  name: 'support',
  components: {
    'illu-support': illuSupport,
  },
  data() {
    return {
      docBaseUrl: process.env.VUE_APP_DOC_URL,
    };
  },
};
</script>
<style lang="sass">
#support
  .page
    @include page
    max-width: 900px
    h1
      color: $white
      margin-bottom: 30px
      svg
        margin-right: 10px
    .illustration
      width: 200px
      margin-bottom: 50px
    p
      @include label
      padding: 1rem 0
      line-height: 1.4
    a
      color: $accent100
      @include link
</style>
